import './BrfSearchSelectedItem.css'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { IconButton } from '@mui/material'

function BrfSearchSelectedItem({ brfName, removedBRFItem }) {
  return (
    <Stack direction="row" spacing={1}>
      <Box className="selectedItem" border={1} borderColor="lightGray" sx={{ width: 500, maxWidth: '100%' }}>
        <p> {brfName}</p>
      </Box>
      <Box className="deleteSelectedBtn" border={1} borderColor="lightGray">
        <IconButton onClick={removedBRFItem}>
          <DeleteOutlinedIcon sx={{ fontSize: 40 }} />
        </IconButton>
      </Box>
    </Stack>
  )
}

export default BrfSearchSelectedItem
