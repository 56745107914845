import React from 'react'

function PushNotification() {
  return (
    <div>
      <h2>Push Notification pages</h2>
    </div>
  )
}

export default PushNotification
