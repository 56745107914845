import { Button, createTheme, ThemeProvider } from '@mui/material'
import React from 'react'

function SalmonButton({ text, onClick, sx }) {
  let theme = createTheme({
    // Theme customization goes here as usual, including tonalOffset and/or
    // contrastThreshold as the augmentColor() function relies on these
  })
  theme = createTheme(theme, {
    palette: {
      salmon: theme.palette.augmentColor({
        color: {
          main: '#FF5733',
        },
        name: 'salmon',
      }),
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <Button color="salmon" variant="contained" onClick={onClick} sx={sx}>
        {text}
      </Button>
    </ThemeProvider>
  )
}

export default SalmonButton
