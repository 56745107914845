import { Box } from '@mui/material'
import SalmonButton from '../Button/SalmonButton'
import useSharedSelectedBrfItemsState from '../util/brfSelectedItemsState'
import './Checkout.css'
import { Link } from 'react-router-dom'

function Checkout() {
  const [items, setItems] = useSharedSelectedBrfItemsState()

  const handleButtonClick = () => console.log('BRF items to checkout: ' + items)

  if (items.length === 0) {
    return <></>
  }
  return (
    <Box
      paddingTop={0}
      textAlign="center"
      sx={{ width: 585, maxWidth: '100%' }}
    >
      <Link to="/checkout">
        <SalmonButton
          text="Köp"
          onClick={handleButtonClick}
          sx={{ width: 585, height: 50, maxWidth: '100%' }}
        ></SalmonButton>
      </Link>
    </Box>
  )
}

export default Checkout
