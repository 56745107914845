import './App.css'
import BrfSearch from './Containers/BrfSearch/BrfSearch'
import KlarnaCheckout from './Pages/Checkout/KlarnaCheckout'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import PushNotification from './Pages/PushNotification/PushNotification'
import ConfirmationPage from './Pages/Confirmation/ConfirmationPage'
import Terms from './Pages/Terms/TermsPage'

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<BrfSearch />}></Route>
          <Route path="/checkout" element={<KlarnaCheckout />}></Route>
          <Route path="/terms" element={<Terms />}></Route>
          <Route path="/confirmation" element={<ConfirmationPage />}></Route>
          <Route path="/push" element={<PushNotification />}></Route>
        </Routes>
      </Router>
    </>
  )
}

export default App
