import { useBetween } from 'use-between'
import { useState } from 'react'

// Make a custom hook with your future shared state
const useBrfItemsState = () => {
  const [items, setItems] = useState([])
  return [items, setItems]
}

// Make a custom hook for sharing your form state between any components
const useSharedSelectedBrfItemsState = () => useBetween(useBrfItemsState)

export default useSharedSelectedBrfItemsState