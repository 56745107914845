
function ConfirmationPage() {
  return (
    <div>
      <h2>Confirmation Page</h2>
    </div>
  )
}

export default ConfirmationPage
