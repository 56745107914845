import BrfSearchSelectedItem from '../BrfSearchItem/BrfSearchSelectedItem'
import { Box, List } from '@mui/material'
import useSharedSelectedBrfItemsState from '../util/brfSelectedItemsState'


function BrfSelectedItems() {
  const [items, setItems] = useSharedSelectedBrfItemsState()
  return (
    <List>
      {items.map((item) => (
        <>
        <BrfSearchSelectedItem
          key={item}
          brfName={item}
          removedBRFItem={() => {
            console.log('Deleting item: ' + item)
            setItems(items.filter((a) => a !== item))
          }}
        />
        <Box paddingTop={1}/>
        </>
      ))}
    </List>
  )
}

export default BrfSelectedItems
