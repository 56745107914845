import React, { useEffect, useState } from 'react';
import axios from 'axios';

const KlarnaCheckoutPage = () => {
  const [klarnaHtmlSnippet, setKlarnaHtmlSnippet] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [orderId, setOrderId] = useState('')

  // Fetch Klarna session from the backend
  const createKlarnaSession = async () => {

    const brfbo_app_url = process.env.REACT_APP_BRFBO_BASE_URL
    const brfbo_app_api_key = process.env.REACT_APP_BRFBO_API_KEY

    try {
      const response = await axios.post(`${brfbo_app_url}/api/v1/payment/checkout`,
        {
          quantity: 1,
          unit_price: 50000,
          tax_rate: 1000,
          locale: "sv-SE",
          productUrl: `${brfbo_app_url}`
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-BrfBo-API-KEY': `${brfbo_app_api_key}`
          }
        }
      ); // Adjust URL as needed
      console.log(response)
      setKlarnaHtmlSnippet(response.data.html_snippet); // Klarna will return this snippet to render the checkout
      setLoading(false);
      setOrderId(response.data.order_id)
    } catch (err) {
      console.log('***** '+err)
      setError('Error creating Klarna session');
      setLoading(false);
    }
  };

  useEffect(() => {
    createKlarnaSession();
  }, []);

  useEffect(() => {
    if (klarnaHtmlSnippet) {
      // Append Klarna's checkout snippet to the div
      const klarnaContainer = document.getElementById('klarna-checkout-container');
      klarnaContainer.innerHTML = klarnaHtmlSnippet;
      console.log('**** ' + klarnaHtmlSnippet)
    }
  }, [klarnaHtmlSnippet]);

  if (loading) {
    return <div>Loading Klarna Checkout...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <h2>Klarna Checkout</h2>
      <div id="klarna-checkout-container"></div> {/* Klarna will inject the widget here */}
    </div>
  );
};

export default KlarnaCheckoutPage;
